import type { GetStaticPaths } from 'next';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ToastContainer } from '../../../../front/src/components/Toast';
import { ProfileCreation } from '../../../../front/src/contributor/Profile/ProfileCreation';
import { useDataExposer } from '../../../../front/src/hooks/useExposedData';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import { useSSREffect } from '../../../../front/src/hooks/useSSREffect';
import { addApolloState } from '../../../../front/src/services/apollo/createApolloClient';
import type { PageType } from '../../../../front/src/types/layout';
import { handleError } from '../../../../front/src/utils/common-utils';
import { NoSSR } from '../../../../front/src/utils/ssr';
import { MetaPage } from '../../components/Meta/MetaPage';
import bg from '../../img/header_banner.jpg';
import { loginPageName } from '../../utils/consts';
import type { GetStaticPropsGD } from '../../utils/getStaticPropsCommon';
import {
  defaultStaticPathsNoPreRender,
  getStaticPropsCommon,
} from '../../utils/getStaticPropsCommon';
import { mkTmpApolloClient } from '../../utils/mkTmpApolloClient';

const messages = defineMessages({
  userNotAllowed: { defaultMessage: 'Compte utilisateur non autorisé.', id: '+GmJPj' },
  authRequired: { defaultMessage: 'Accès restreint, merci de vous authentifier.', id: 'o297jA' },
  requestAccess: { defaultMessage: "Contactez-nous pour obtenir l'accès.", id: 'f6dxvy' },
});

interface Props {}

export const Login: PageType<Props> = () => {
  const dataExposer = useDataExposer();
  const { push, prefetch, query } = useRouter();

  const from = Array.isArray(query.from) ? query.from[0] : query.from;
  const loginUrl = from || '/';

  const [navigating, setNavigating] = useState(false);
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);

  const hideStressfulMessages = navigating || authModalIsOpen || profileModalIsOpen;

  const { authUserId = '', login, isVip, onModalOpenChange } = usePermissions();

  useEffect(() => {
    // Auto-show the login modal when loading the login page.
    // I'm pretty sure it was already done by another code snippet somewhere else, but it seems to have stopped working and I can't find where it is, so I added it here.
    login();
  }, [login]);

  useEffect(() => onModalOpenChange?.(setAuthModalIsOpen), [onModalOpenChange]);

  useSSREffect(() => {
    dataExposer.setDataExposed({ pageName: 'login' });
  }, [dataExposer]);

  useEffect(() => {
    if (isVip) {
      // Redirecting the user to the home page takes a while. During that time, we show a loader to indicate the user we're navigating. Otherwise, he would just see a confusing message like "Access denied".
      setNavigating(true);
      push(loginUrl).catch(e => {
        handleError(e);
        setNavigating(false);
      });
    }
  }, [push, isVip, loginUrl]);

  useEffect(() => {
    // Prefetch the home page in an attempt to load it faster once the login is completed.
    prefetch(loginUrl);
  }, [loginUrl, prefetch]);

  return (
    <>
      <MetaPage title="Login" index={false} follow={false} canonicalPath="/login" />
      <ProfileCreation vipRequired onOpenCloseProfileModal={setProfileModalIsOpen} />
      <div className="text-primary-text text-center px-5 py-30 bg-header-banner bg-cover flex text-xl flex-col items-center h-screen absolute inset-0">
        <div className="absolute left-0 right-0 top-0 bottom-0 -z-10">
          <Image src={bg} layout="fill" objectFit="cover" />
        </div>
        <div className="text-white">
          <NoSSR>
            {hideStressfulMessages ? <>&nbsp;</> : <FormattedMessage {...messages.authRequired} />}
          </NoSSR>
        </div>
        <button
          className="my-10 px-3 flex items-center appearance-none border rounded-md h-touch text-gray-dark select-none cursor-pointer bg-gray-lightest border-gray-light focus:shadow text-base"
          onClick={login}
          type="button"
        >
          Connexion
        </button>

        {authUserId && !isVip && !hideStressfulMessages && (
          <div className="text-white mt-5 text-lg">
            <div>
              <FormattedMessage {...messages.userNotAllowed} />
            </div>
            <div className="mt-8 mb-4 text-md">
              <FormattedMessage {...messages.requestAccess} />
            </div>
          </div>
        )}
      </div>
      <ToastContainer limit={2} />
    </>
  );
};

// See packages/graphdebate/src/pages/[website]/t/[slug].tsx for getStaticProps instructions
export const getStaticProps: GetStaticPropsGD = async context => {
  // Common code
  let { response } = await getStaticPropsCommon(context, Login);
  if (response && ('redirect' in response || 'notFound' in response)) {
    return response;
  }
  const apolloClient = mkTmpApolloClient(context);
  // /Common code

  response.props = {
    ...response.props,
    pageConfig: { previousPage: null, skipLayout: true },
  };
  response = addApolloState(apolloClient, response);
  return response;
};

export const getStaticPaths: GetStaticPaths = defaultStaticPathsNoPreRender;

// Beware if changing the value, this page name is also used in the middleware to identify the path of the login page and know if the user is on the login page or not.
// In general, there may also be another usage of .pageName() I forgot.
Login.pageName = () => loginPageName;

export default Login;
