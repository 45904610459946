
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[website]/login",
      function () {
        return require("private-next-pages/[website]/login.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[website]/login"])
      });
    }
  